export const BG_COLOR = {
  YELLOW: '!bg-[#CA8A04]',
  BLUE: '!bg-[#3B82F6]',
  GREEN: '!bg-[#22C55E]',
  RED: '!bg-[#EF4444]',
  GREY: '!bg-[#4B5563]',
  PURPLE: '!bg-[#9333EA]',
};

const DEFAULT_TAG_CLASSES = '!w-full !px-[8px] !py-[2px] !my-[2px] !text-white !bg-opacity-80';
const CIRCLE_TAG_CLASSES = '!w-auto !my-[2px] !pl-2 !pr-3 !py-[2px] !text-white !text-left !rounded-xl !bg-opacity-80';

export const ENUM_TAG = {
  ApprovalStatus: {
    SUBMIT: `${CIRCLE_TAG_CLASSES} ${BG_COLOR.BLUE}`,
    RETRACT: `${CIRCLE_TAG_CLASSES} ${BG_COLOR.YELLOW}`,
    APPROVED: `${CIRCLE_TAG_CLASSES} ${BG_COLOR.GREEN}`,
    REJECT: `${CIRCLE_TAG_CLASSES} ${BG_COLOR.RED}`,
  },
  TransferTransactionStatus: {
    TRANSFERRING: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.YELLOW}`,
    TRANSFERRED: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.BLUE}`,
    CONFIRMED: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.GREEN}`,
    FORCE_CONFIRMED: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.GREEN}`,
    CANCELLED: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.RED}`,
    FORCE_CANCELLED: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.RED}`,
    FAILED: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.RED}`,
  },
  PaymentOrderStatus: {
    PENDING: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.YELLOW}`,
    PROCESSING: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.BLUE}`,
    CANCELED: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.RED}`,
    SUCCESS: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.GREEN}`,
    FAILED: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.RED}`,
  },
};

export const SELECTION_TAG = {
  rolePos: {
    ROLE_IT: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.GREY}`,
    ROLE_AGENT: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.PURPLE}`,
    ROLE_SUPER: `${DEFAULT_TAG_CLASSES} ${BG_COLOR.YELLOW}`,
  },
};
